<template>
  <div>
    <h1>{{ youngs.length }} Utilisateurs bloqués </h1>
    <BTable id="block-table" :fields="fields" :items="youngs" :per-page="perPage" :current-page="currentPage">
      <template #cell(nom)="data">
        <div>
          {{ data.item.lastName }}
        </div>
      </template>
      <template #cell(prenom)="data">
        <div>
          {{ data.item.firstName }}
        </div>
      </template>
      <template #cell(numéro)="data">
        <div>
          {{ data.item.phoneNumber }}
        </div>
      </template>
      <template #cell(action)="data">
        <div class="blocklist-actions">
          <b-button v-if="canUnblock" variant="outline-primary" @click="openModal(data.item)"> Débloquer
          </b-button>
          <b-modal ref="modal" hide-header hide-footer title="Débloquer le numéro">
            <h2 class="text-primary text-center mb-2">
              Débloquer l'utilisateur
            </h2>
            <p class="text-center">
              Êtes-vous sûr de vouloir débloquer cet utilisateur ?
            </p>
            <p class="text-center mb-3">
              Dès lors, la personne pourra de nouveau contacter la ligne Le Refuge.
            </p>
            <div class="d-flex justify-content-end">
              <b-button variant="danger" class="mr-2" @click="closeModal()">
                Annuler
              </b-button>
              <b-button variant="primary" @click="validateUnblock()">
                Valider
              </b-button>
            </div>
          </b-modal>
          <b-button variant="outline-primary" @click="openModalInfos(data.item)">
            Informations
          </b-button>
          <b-modal ref="modalInfos" hide-header hide-footer title="Informations sur le blocage">
            <h2 class="text-primary text-center mb-2">
              Informations sur le blocage
            </h2>
            <p class="text-center">
              Bloqué par l'intervenant : {{ blockedBy.userFirstName }} {{ blockedBy.userLastName }}
            </p>
            <p class="text-center mb-3">
              le {{ formatDate(blockedBy.date) }}
            </p>
            <div class="d-flex justify-content-end">
              <b-button variant="primary" @click="closeModalInfos()">
                Fermer
              </b-button>
            </div>
          </b-modal>
        </div>
      </template>
    </BTable>
    <b-pagination v-if="rows > perPage" v-model="currentPage" :total-rows="rows" :per-page="perPage"
                  aria-controls="block-table" align="center" />
  </div>
</template>

<script>
import {
  BTable, BButton, BModal, BPagination,
} from 'bootstrap-vue'
import store from '@/store' // eslint-disable-line
// eslint-disable-next-line import/no-cycle
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BTable,
    BButton,
    BModal,
    BPagination,
  },
  setup() {
    if (store.state.youngs.youngs.length === 0) {
      store.dispatch('youngs/fetchYoungs')
    }
    return {
      formatDate,
    }
  },
  data() {
    return {
      fields: [
        { key: 'nom', sortable: true },
        { key: 'prenom', sortable: true },
        { key: 'numéro', sortable: true },
        { key: 'action', sortable: false },
      ],
      perPage: 10,
      currentPage: 1,
      currentData: {},
      blockedBy: '',
    }
  },
  computed: {
    youngs() {
      return (store.getters['youngs/youngs'] || []).filter(e => e.isBlocked)
    },
    canUnblock() {
      return store.getters['users/getUserRole'].includes('Coordinateur')
    },
    rows() {
      return this.youngs.length
    },
  },
  methods: {
    manageBlockStatus() {
      const {
        address, department, region, zipCode, archivedForFiveYears, dateOfBirth, city, firstName, id, lastName, email, gender, demandType, howThePersonGotToKnowUs, demandReason, situationOfAccommodation, isBlocked,
      } = this.currentData
      const youngUpdate = {
        address, department, region, zipCode, archivedForFiveYears, dateOfBirth, city, firstName, id, lastName, email, gender, demandType, howThePersonGotToKnowUs, demandReason, situationOfAccommodation, isBlocked: !isBlocked,
      }
      store.dispatch('youngs/updateYoungDetails', youngUpdate)
    },
    openModal(young) {
      this.currentData = young
      this.$refs.modal.show()
    },
    closeModal() {
      this.$refs.modal.hide()
    },
    validateUnblock() {
      this.manageBlockStatus()
      this.$refs.modal.hide()
    },
    async openModalInfos(young) {
      await store.dispatch('youngs/fetchBlockedBy', young.id)
      this.blockedBy = store.getters['youngs/getBlockedBy']
      this.$refs.modalInfos.show()
    },
    closeModalInfos() {
      this.$refs.modalInfos.hide()
      this.blockedBy = ''
    },
  },
}
</script>

<style>
h1 {
  color: #16B5EA;
  text-transform: capitalize;
  margin-bottom: 25px;
}
.blocklist-actions {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
</style>
